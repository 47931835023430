import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames/bind';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import ToastContext from '../../contexts/ToastContext';
import useAccessibleDropdown from '../../hooks/useAccessibleDropdown';
import useOuterClick from '../../hooks/useOuterClick';
import { checkClipboardSupport, checkWindowSupport } from '../../utils/compatibility';
import nClicks from '../../utils/nClicks';
import Log from '../../utils/log';
import styles from './SharePost.module.scss';

const cx = classNames.bind(styles);

export type SharePostProps = {
  isPostPage?: boolean;
};

function SharePost({ isPostPage }: SharePostProps): JSX.Element {
  const { t } = useTranslation('common');
  const { setToastMessage } = useContext(ToastContext);

  const fullUrl = checkWindowSupport() ? window.location.origin + window.location.pathname : '';

  // accessibility를 고려한 dropdown
  const shareButtonRef = useRef<HTMLButtonElement>(null);
  const listboxRef = useRef<HTMLUListElement>(null);
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    handleDropdownItemKeydown,
    handleToggleButtonClick,
    handleToggleButtonKeydown,
  } = useAccessibleDropdown(shareButtonRef, listboxRef);
  const closeDropdown = useCallback(() => setIsDropdownOpen(false), [setIsDropdownOpen]); // body에 적용해야 해서 useCallback 사용

  const handleShareButtonClick = (e: React.MouseEvent) => {
    if (isPostPage) {
      nClicks(e, 'post.share');
    }
    handleToggleButtonClick(e);
  };

  // outer click
  useOuterClick(closeDropdown, isDropdownOpen);

  // clipboard
  const copyToClipboard = (text: string) => {
    // - server-side render시엔 navigator 접근 전에 early return
    // - IE에서 구동 시 에러 반환
    if (!checkWindowSupport()) {
      return Promise.resolve();
    } else if (!checkClipboardSupport()) {
      return Promise.reject({ message: t('clipboard_api_not_supported') });
    }
    return navigator.clipboard.writeText(text);
  };

  // UI event handlers
  const handleCopyUrlClick = async () => {
    try {
      await copyToClipboard(fullUrl);
      setToastMessage?.({ type: 'URL_COPIED', message: t('toast_post_url_copied') });
    } catch (e) {
      // IE 대응
      const message = (e as { message: string }).message;
      Log.print({ level: 'warn', message });
    }
    closeDropdown();
  };

  const handleCopyUrlKeydown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      handleCopyUrlClick();
    } else {
      // accessibility 관련 처리는 useAccessibleDropdown의 handler 사용
      handleDropdownItemKeydown(e, index);
    }
  };

  return (
    <div className={cx('post_share_wrap')} onClick={(e) => e.stopPropagation()}>
      <button
        type="button"
        className={cx('post_share_button')}
        aria-labelledby="post-share-blind"
        aria-haspopup={'listbox'}
        aria-expanded={isDropdownOpen}
        onClick={handleShareButtonClick}
        onKeyDown={handleToggleButtonKeydown}
        ref={shareButtonRef}
      >
        <span className="blind" id="post-share-blind">
          {t('share_post')}
        </span>
      </button>
      <ul className={cx('post_share_list')} role="listbox" aria-label="post-share-list" ref={listboxRef}>
        <li className={cx('post_share_item')} role="none">
          <button
            type="button"
            className={cx('post_share_item_button')}
            role="option"
            aria-selected={false}
            aria-label="copy-url"
            tabIndex={0}
            onKeyDown={(e) => handleCopyUrlKeydown(e, 0)}
            onClick={handleCopyUrlClick}
          >
            Copy URL
          </button>
        </li>
        <li className={cx('post_share_item')} role="none">
          <FacebookShareButton
            url={fullUrl}
            resetButtonStyle={false}
            type="button"
            role="option"
            aria-selected={false}
            aria-label="facebook"
            tabIndex={0}
            beforeOnClick={closeDropdown}
            onKeyDown={(e) => handleDropdownItemKeydown(e, 1)}
          >
            Facebook
          </FacebookShareButton>
        </li>
        <li className={cx('post_share_item')} role="none">
          <TwitterShareButton
            url={fullUrl}
            type="button"
            role="option"
            resetButtonStyle={false}
            aria-selected={false}
            aria-label="twitter"
            tabIndex={0}
            beforeOnClick={closeDropdown}
            onKeyDown={(e) => handleDropdownItemKeydown(e, 2)}
          >
            Twitter
          </TwitterShareButton>
        </li>
        <li className={cx('post_share_item')} role="none">
          <LinkedinShareButton
            url={fullUrl}
            type="button"
            role="option"
            resetButtonStyle={false}
            aria-selected={false}
            aria-label="linkedin"
            tabIndex={0}
            beforeOnClick={closeDropdown}
            onKeyDown={(e) => handleDropdownItemKeydown(e, 3)}
          >
            LinkedIn
          </LinkedinShareButton>
        </li>
      </ul>
    </div>
  );
}

export default SharePost;
