import React, { useMemo } from 'react';
import Flicking, { Plugin, ViewportSlot } from '@egjs/react-flicking';
import { AutoPlay, Pagination } from '@egjs/flicking-plugins';
import '@egjs/react-flicking/dist/flicking.css';

export type CarouselProps = {
  children: React.ReactNode;
  id: string;
  className: string;
  plugins?: Plugin[];
  panelsPerView?: number;
};

// pagination은 혼자서 말썽을 부려서 따로 변수로 뺐습니다. (문제 생길 때마다 init 해줘야해서)
const createFlickingPlugins = (plugins: Plugin[]) => {
  const pagination = new Pagination({
    type: 'bullet',
    renderBullet: (classNames, index) =>
      `<li class='${classNames}' role='button'>
        <span class='blind'>${index + 1}</span>
      </li>`,
  });

  return {
    plugins: [...plugins, new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }), pagination],
    pagination,
  };
};

function Carousel({ children, id, className, plugins = [], panelsPerView = 1 }: CarouselProps): JSX.Element {
  const { plugins: flickingPlugins, pagination } = useMemo(() => createFlickingPlugins(plugins), [plugins]);

  return (
    <Flicking
      viewportTag="ul"
      id={id}
      className={className}
      align="center"
      circular={true}
      panelsPerView={panelsPerView}
      horizontal={true}
      plugins={flickingPlugins}
      moveType="strict"
      onAfterResize={(e) => {
        if (!e.currentTarget.element.querySelector('.flicking-pagination-bullet')) {
          pagination.init(e.currentTarget);
        }
      }}
    >
      {children}
      <ViewportSlot>
        {/* ".flicking-pagination"은 Flicking 라이브러리에서 지정한 classname (수정 X) */}
        {/* TODO: flicking 측에서 type 선언을 잘못하여 에러가 발생, 라이브러리 업데이트가 되면 아래 ts-ignore 삭제 */}
        {/* @ts-ignore */}
        <div className="flicking-pagination" aria-live="polite"></div>
      </ViewportSlot>
    </Flicking>
  );
}

export default Carousel;
