/**
 * array를 chunk 단위로 잘라서 새로운 array를 생성
 * ex. chuckArray([1, 2, 3], 2) => [[1, 2], [3]]
 * @param array 대상 array
 * @param size chunk의 크기
 * @returns chunk 단위로 잘린 array들의 array
 */
export function chunkArray<T>(array: T[], size: number): T[][] {
  if (size <= 0) {
    throw new Error('size should be a positive value.');
  }

  const chuckedArray: T[][] = [];
  for (let i = 0; i < array.length; i = i + size) {
    chuckedArray.push(array.slice(i, i + size));
  }

  return chuckedArray;
}
