import React from 'react';
import classNames from 'classnames/bind';
import Image from 'next/image';
import Post from '../../types/post';
import { getAuthorProfileImageSrc } from '../../utils/postInfo';
import styles from './PostThumbnail.module.scss';

const cx = classNames.bind(styles);
const IMAGE_QUALITY = 92;

type PostThumbnailProps = {
  className: string; // 부모 요소에서 이미지 영역 잡아주어야 함
  isScheduled: Post['isScheduled'];
  thumbUrl?: string;
  videoUrl?: string;
  interviewMetadata?: Post['interview']; // interview metadata가 있는 경우 interview용 스펙으로 표시
};

function PostThumbnail({
  className,
  isScheduled,
  thumbUrl,
  videoUrl,
  interviewMetadata,
}: PostThumbnailProps): JSX.Element {
  /* [D] 인터뷰 포맷일 때 */
  if (interviewMetadata) {
    const { intervieweeProfileImage, designType } = interviewMetadata;
    const profileImageSrc = getAuthorProfileImageSrc(intervieweeProfileImage); // 없을 경우 default image 표시

    return (
      /* [D] 들어가는 문양에 따라 '-case1' ~ '-case4' 까지 클래스 추가해주세요 */
      <div className={cx('thumbnail', className, 'is_interview', `-case${designType}`)}>
        <span className={cx('thumbnail_wrap')}>
          <div className={cx('thumbnail_image')}>
            {!videoUrl && (
              <Image
                src={profileImageSrc}
                alt={'interviewee profile image'}
                quality={IMAGE_QUALITY}
                layout="fill"
                priority
              />
            )}
            {videoUrl && <video loop autoPlay muted preload="auto" playsInline src={videoUrl}></video>}
          </div>
        </span>
      </div>
    );
  }

  return (
    <div className={cx('thumbnail', className)}>
      <span className={cx('thumbnail_wrap')}>
        <div
          className={cx('thumbnail_image', { '-no_image': !thumbUrl && !videoUrl }, { [`-coming_soon`]: isScheduled })}
        >
          {!videoUrl && thumbUrl && (
            <Image src={thumbUrl} alt={'post thumbnail'} quality={IMAGE_QUALITY} layout="fill" priority />
          )}
          {videoUrl && <video loop autoPlay muted preload="auto" playsInline src={videoUrl}></video>}
        </div>
      </span>
    </div>
  );
}

export default PostThumbnail;
