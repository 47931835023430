import React from 'react';
import classNames from 'classnames/bind';
import Image from 'next/image';
import { useRouter } from 'next/router';
import SharePost from './SharePost';
import Post from '../../types/post';
import { LocaleCode } from '../../types/locale';
import { usePCWideMediaQuery } from '../../hooks/useResponsiveMediaQuery';
import { formatDate } from '../../utils/date';
import { getAuthorProfileImageSrc, getFullAuthorName } from '../../utils/postInfo';
import styles from './PostInfo.module.scss';

const cx = classNames.bind(styles);

export type PostInfoProps = {
  createdAt: Post['createdAt'];
  authors: Post['authors'];
  isScheduled: Post['isScheduled'];
  isPostPage?: boolean;
  isSharePostEnabled?: boolean;
  className?: string;
};

function PostInfo({
  createdAt,
  authors,
  isScheduled,
  isPostPage,
  isSharePostEnabled,
  className,
}: PostInfoProps): JSX.Element {
  const { locale } = useRouter();
  const formattedCreatedAt = formatDate(createdAt, locale as LocaleCode);
  const fullAuthorName = getFullAuthorName(authors, locale as LocaleCode);
  const isPCWide = usePCWideMediaQuery();

  return (
    <div className={cx('post_info', className, { '-post': isPostPage })}>
      <div className={cx('post_author_image_wrap')}>
        {authors.map((author, index) => {
          const profileImageSrc = getAuthorProfileImageSrc(author.profileImage);
          const length = isPostPage && isPCWide ? 28 : 20;
          const key = `${author.team}-${author.name ?? ''}-${author.profileImage ?? ''}`;

          return (
            <div key={key} className={cx('post_author_image', isPostPage && isPCWide && '-width28')}>
              <Image
                src={profileImageSrc}
                alt={'profile'}
                width={length}
                height={length}
                layout="fill"
                objectFit="cover"
                priority
              />
            </div>
          );
        })}
      </div>
      <span className={cx('post_author')}>{fullAuthorName}</span>
      {isScheduled ? (
        <em className={cx('post_date', '-coming_soon')}>coming soon</em>
      ) : (
        <span className={cx('post_date')}>{formattedCreatedAt}</span>
      )}
      {isSharePostEnabled && <SharePost isPostPage={isPostPage} />}
    </div>
  );
}

export default PostInfo;
