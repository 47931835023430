import classNames from 'classnames/bind';
import Link from 'next/link';
import React from 'react';
import nClicks from '../../utils/nClicks';
import { getTagPageUrl } from '../../utils/url';
import styles from './Tags.module.scss';

const cx = classNames.bind(styles);

export type TagsProps = {
  tags: string[];
  nClicksCode?: string;
};

function Tags({ tags, nClicksCode }: TagsProps): JSX.Element {
  const requestNClicks = (e: React.MouseEvent) => {
    if (nClicksCode) {
      nClicks(e, nClicksCode);
    }
  };

  return (
    <>
      <ul className={cx('tag_list')}>
        {tags.map((tag) => {
          const url = getTagPageUrl(tag, 1);

          return (
            <li key={tag} className={cx('tag_item')}>
              <Link href={url}>
                <a className={cx('tag_link')} onClick={requestNClicks}>
                  # {tag}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Tags;
