/* eslint-disable no-console */
import Sentry from './sentry';

interface ConsoleOptions {
  level: 'log' | 'warn' | 'error';
  message: string;
  exception?: any; // try catch문의 exception의 type은 unknown으로 정의되어 있음
}

interface SentryOptions {
  exception?: any; // Sentry에서 exception의 type을 any로 정의
  message?: string;
}

interface GroupOptions {
  prefix?: string;
  isNumbered?: boolean;
}

export default class Log {
  static print(consoleOptions?: ConsoleOptions, sentryOptions?: SentryOptions) {
    if (!consoleOptions && !sentryOptions) {
      throw new Error('at least one of options should be provided');
    }

    // console
    if (consoleOptions) {
      const { level, message, exception } = consoleOptions;

      switch (level) {
        case 'log':
        default:
          console.log(message);
          break;
        case 'warn':
          console.warn(message);
          break;
        case 'error':
          console.error(message);
          break;
      }

      // 추가로 exception object 자체를 print하고 싶은 경우 사용
      if (exception) {
        console.error(exception);
      }
    }

    // sentry
    if (sentryOptions) {
      const { exception, message } = sentryOptions;

      if (exception) {
        Sentry.captureException(exception);
      }
      if (message) {
        Sentry.captureMessage(message);
      }
    }
  }

  static printGroup(title: string, messages: string[], options: GroupOptions = {}) {
    console.group(title);
    messages.forEach((message, index) => {
      const { prefix, isNumbered } = options;
      console.log(`${prefix ?? ''}${isNumbered ? `${index + 1}. ` : ''}${message}`); // prefix와 numbering을 모두 사용할 경우 prefix 다음에 number를 표시
    });
    console.groupEnd();
  }
}
