import Author from '../types/author';
import { LocaleCode } from '../types/locale';
import Post from '../types/post';

export const DEFAULT_PROFILE_IMAGE = 'clova-default-profile.png';

export function getPostContentSrc(path: string) {
  // 맨 앞에 나오는 '/public'만 제거
  const regexp = /^\/public(.*)/;
  const result = regexp.exec(path);
  if (result && result.length > 1) {
    return result[1];
  }
  return path;
}

export function getAuthorProfileImageSrc(authorProfileImage: Author['profileImage']) {
  const filename = authorProfileImage ?? DEFAULT_PROFILE_IMAGE;
  return `/contents/authors/${filename}`;
}

export function createPostId(
  localeCode: Post['localeCode'],
  year: Post['year'],
  month: Post['month'],
  title: Post['title']
): Post['id'] {
  return `${localeCode}-${year}-${month}-${title}`;
}

/**
 * 작성자의 팀명과 현재 locale을 고려하여 적절한 포맷의 작성자명 텍스트 반환
 * ex) "김박사, 박석사 (AI LAB), 아무개 (AI Product)"
 * @param authors 작성자 정보
 * @param locale 언어
 * @returns 팀별로 묶인 작성자 이름
 */
export function getFullAuthorName(authors: Post['authors'], locale: LocaleCode) {
  const teams: { [key: string]: string[] } = {};

  // team별로 grouping
  authors.forEach(({ name, team }) => {
    // 처음 나오는 team명이면 empty list로 초기화
    if (!teams[team]) {
      teams[team] = [];
    }

    // 각 team마다 작성자명 추가
    if (name) {
      teams[team].push(name);
    }
  });

  // 한 줄의 text로 반환
  const fullAuthorName = Object.entries(teams).reduce((acc, [team, members], index) => {
    let teamAuthorName = '';
    if (members.length > 0) {
      // 구성원이 있는 경우: ex) "김박사, 박석사 (AI LAB)"
      const memberNames = members.join(', ');
      teamAuthorName = `${memberNames} (${team})`;
    } else {
      // 팀 단위로 작성하여 구성원이 없는 경우: ex) "AI LAB"
      teamAuthorName = team;
    }

    if (index === 0) {
      return teamAuthorName;
    }
    return `${acc}, ${teamAuthorName}`;
  }, '');

  return fullAuthorName;
}
