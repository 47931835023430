import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { LocaleCode } from '../types/locale';

export function formatDate(date: Date, localeCode: LocaleCode) {
  switch (localeCode) {
    case 'en':
      return format(date, 'MMM d, yyyy');
    case 'ko':
    default:
      return format(date, 'yyyy.MM.dd');
  }
}

/**
 * timestamp 형식으로 formatting된 string 반환
 * @param time formatting 할 Date
 * @param locale 요일 표시할 때 사용할 locale (date-fns/locale에서 import)
 * @returns formatting 된 string (ex. "2022.06.17 금요일 10:00")
 */
export function formatTimestamp(time: Date, locale = ko) {
  return format(time, 'yyyy.MM.dd eeee HH:mm', { locale });
}

/**
 * JSON 변환으로 인해 string type이 된 property들을 Date type으로 변환
 * @param obj Date type의 property가 있는 object
 * @param properties Date type을 가지는 obj의 property 목록 (동시에 여러 property 변환 가능)
 * @returns Date object로 변환된 property들을 가지고 있는 object
 */
export function restoreDateProperties<O>(obj: O, properties: (keyof O)[]) {
  const result = { ...obj };
  // @ts-ignore (Type 'Date' is not assignable to type 'O[keyof O]' 발생)
  properties.forEach((property) => (result[property] = new Date(result[property])));
  return result;
}
